import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Container from '../components/container'
import Newsletter from '../components/footer/newsletter'
import Slider from 'react-slick'
import '../components/slick/slick.scss';
import '../components/slick/slick-theme.scss';

export default ({ data }) => {

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 450;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    centerMode: true,
    // centerPadding: 0,
    variableWidth: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          // variableWidth: false,
        }
      }
    ],
  };

  return (
    <Layout mode="dark">
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta} path={data.wordpressPage.slug} />
      <Hero
        mode="default"
        title={data.wordpressPage.acf.header_text}
        content={data.wordpressPage.acf.header_content}
        image={ data.wordpressPage.featured_media ?
            data.wordpressPage.featured_media.localFile.childImageSharp.fluid
          :
            null
          }
        button={
          {
            title: typeof data?.wordpressPage?.acf?.header_button?.title !== 'undefined' ? data.wordpressPage.acf.header_button.title : '',
            url: typeof data?.wordpressPage?.acf?.header_button?.url !== 'undefined' ? data.wordpressPage.acf.header_button.url : '',
            target: typeof data.wordpressPage.acf.header_button.target !== 'undefined' ? data.wordpressPage.acf.header_button.target : '',
          }
        }
      />
      <Container>
        <div style={{textAlign: 'center', margin: '100px 0'}}>
          <h3>Questions?</h3>
          <p>
            Contact <a href="mailto:scholarship@mtafoundation.org" target="_blank">scholarship@mtafoundation.org</a> for more information.
          </p>
        </div>
      </Container>

      <div style={{background: 'linear-gradient(to bottom, rgb(138, 198, 64) 50%, transparent)', paddingTop: '100px', color: 'white'}}>
        <div className="boardSlider">
          <Container>
            <h2 className="title" style={{textAlign: 'center'}}>2024 Scholarship Program</h2>
            <p style={{marginBottom: '100px', textAlign: 'center'}}>The MTA Foundation is proud to award over $75,000 in scholarships toward the education of 31 amazing students from our service area! Selected from numerous applications, these recipients are the next generation of pioneers for Alaska.</p>

            <h2 className="title" style={{textAlign: 'center', marginBottom: '100px'}}>Recipients</h2>
          </Container>
          <Slider {...settings}>
            {
              data.allWordpressWpAwardees.edges.map((n, i) => {
                return (
                  <div>
                    <BackgroundImage tag="div" className="slickSlideBG" fluid={n.node.featured_media.localFile.childImageSharp.fluid}>
                      <div className="slickSlideOverlay">
                        <h4>{n.node.title}</h4>
                        <h5>Recipient</h5>
                        <div className="bio" dangerouslySetInnerHTML={{ __html: text_truncate(n.node.content) }} />
                      </div>
                    </BackgroundImage>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>

      <Newsletter />
    </Layout>
  )
}

export const pageQuery = graphql`
query ScholarshipQuery {
  wordpressPage(wordpress_id: {eq: 67}) {
    title
    slug
    acf {
      header_content
      header_text
      header_button {
        url
        title
        target
      }
      rules_of_eligibility {
        rules_header
        rule_1
        rule_2
        rule_3
        terms_header
        terms_condition
      }
      intro_green_box
    }
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  allWordpressWpAwardees(filter: {categories: {elemMatch: {slug: {eq: "scholarship-awardee"}}}}) {
    edges {
      node {
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title
        content
      }
    }
  }
}
`
